.ChatPage__ChatUserList {
  overflow: hidden !important;
    
}

.ChatUserList__Logout{
    color: rgb(93, 92, 92);
}

.ChatUserList__Logout:hover{
    text-decoration: underline;
}

  
  .RecentChatList__container::-webkit-scrollbar {
    display: none; /* For WebKit-based browsers (Chrome, Safari) */
  }
/* Add these styles to your CSS file */

.recent-chat-item {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.recent-chat-item:hover {
  background-color: #f5f5f5;
}

.profile-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.chat-details {
  flex: 1;
}

h5 {
  font-size: 16px;
  margin: 0;
  color: #333;
}

.last-message {
  font-size: 14px;
  color: #777;
  margin: 4px 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100% !important;
  overflow: hidden !important;
}

.chat-date {
  text-align: right;
  font-size: 12px;
  color: #999;
}

.RecentChatItem {
  position: relative;
  cursor: pointer;
  transition: .2s ease-in-out;
}
.RecentChatItem--selected{
  border-color: var(--primaryColor);
}
/* .RecentChatItem--selected::before {
  position: absolute;
  content: " ";
  height: calc(100% + 10px);
  width: 4px;
  top: -5px;
  left: -.5em;
  background-color: var(--primaryColor);
  z-index: 2;
}
.RecentChatItem--selected::after {
  position: absolute;
  content: " ";
  height: calc(100% + 10px);
  width: calc(100% + 1.6em);
  top: -5px;
  left: -.5em;
  background-color: rgba(255, 255, 255, 0.355);
  z-index: 1;
}
 */
.RecentChatItem h5{
  transition: .2s ease-in-out;
}

/* .RecentChatItem:hover h5, .RecentChatItem--selected h5{
  color: white !important;
} */

.RecentChatItem>img {
  border-radius: 100%;
  width: 40px;
  height: 40px;
}
.ChatPage {
    width: 100vw;
    height: 100vh;
    --chatList-XS-Width: 100vw;
}

.ChatPage__ChatUserList {
    height: 100vh;
    width: 35vw;
}

.ChatUserList__container {
    color: var(--textColor) !important;
}

.initial-image {
    height: 35px;
    width: 35px;
    border-radius: 100%;
}

@media (min-width: 1024px) {
    .ChatPage.user-chat {
        width: 25%;
    }

    .ChatPage.user-chat .ChatPage__content {
        border-radius: 0.375em;
        border: 1px solid var(--bs-border-color) !important;
        box-sizing: border-box;
    }

    .ChatPage.user-chat .ChatPage__content__topbar {
        border-radius: 0.375em 0.375em 0 0;
    }

    .ChatPage.user-chat .toggle-icon {
        margin-left: 0;
        margin-right: 0;
        font-size: 1.5em;
    }

    /* Webkit browsers (Chrome, Safari) */
    .ChatPage.user-chat .ChatContent__Container::-webkit-scrollbar {
        display: none;
    }

}

@media (max-width: 1024px) {
    .ChatPage__ChatUserList {
        width: 35vw;
    }

    .ChatPage__content {
        width: 65vw !important;
        overflow: hidden;
    }
}


@media (max-width: 992px) {
    .ChatPage__ChatUserList {
        width: 35vw;
    }

    .ChatPage__content {
        width: 65vw !important;
        overflow: hidden;
    }
}


@media (max-width: 768px) {
    .ChatPage__ChatUserList {
        width: 40vw;
    }

    .ChatPage__content {
        width: 60vw !important;
        overflow: hidden;
    }
}


@media (max-width: 576px) {
    .ChatPage__ChatUserList {
        width: var(--chatList-XS-Width);
    }

    .ChatPage__content {
        width: calc(100vw - var(--chatList-XS-Width)) !important;
        overflow: hidden;
    }
}
.SpinnerScalingDotRing {
    --d: 24.6px;
    width: 4.5px;
    height: 4.5px;
    border-radius: 50%;
    color: #006ab1;
    box-shadow: calc(1*var(--d))      calc(0*var(--d))     0 0,
           calc(0.707*var(--d))  calc(0.707*var(--d)) 0 1.1px,
           calc(0*var(--d))      calc(1*var(--d))     0 2.2px,
           calc(-0.707*var(--d)) calc(0.707*var(--d)) 0 3.4px,
           calc(-1*var(--d))     calc(0*var(--d))     0 4.5px,
           calc(-0.707*var(--d)) calc(-0.707*var(--d))0 5.6px,
           calc(0*var(--d))      calc(-1*var(--d))    0 6.7px;
    animation: spinner-a90wxe 1s infinite steps(8);
 }
 
 @keyframes spinner-a90wxe {
    100% {
       transform: rotate(1turn);
    }
 }
.CustomMessageBox {
    width: 100%;
    margin: .3em 0 .3em 0;
}


.CustomMessageBox__content {
    background-color: #0084a8;
    color: white;
    padding: 1.0rem 1.75rem;
    animation: fadeIn 3s;
  -webkit-animation: fadeIn 3s;
  -moz-animation: fadeIn 3s;
  -o-animation: fadeIn 3s;
  -ms-animation: fadeIn 3s;

}

.CustomMessageBox.right {
    justify-content: end;
}

.CustomMessageBox.left {
    justify-content: start;
}

.CustomMessageBox.right .CustomMessageBox__content {
    margin-right: 15px;
}

.CustomMessageBox.right .CustomMessageBox__content {
    justify-content: start;
    margin-left: 15px;
    background-color: var(--messageRigthtBg);
    color: var(--messageRigthColor);
    /*     box-shadow: 1px 1px 1px 1px #0003; */
    border-radius: 20px 20px 20px 20px !important;
}

.CustomMessageBox.left .CustomMessageBox__content {
    justify-content: start;
    margin-left: 15px;
    background-color: var(--messageLeftBg);
    color: var(--messageLeftColor);
    /*     box-shadow: 1px 1px 1px 1px #0003; */
    border-radius: 20px 20px 20px 20px !important;
}

.CustomMessageBox .timestamp {
    color: var(--timestampLeftColor) !important;
    font-size: .75rem !important;
}



.CustomMessageBox .CustomMessageBox__content video,
.CustomMessageBox .CustomMessageBox__content img,
.CustomMessageBox .CustomMessageBox__content audio {
    display: block;
    max-width: 100%;
    height: auto;
}

.CustomMessageBox .CustomMessageBox__content img{
    height: 200px;
}

.CustomMessageBox .CustomMessageBox__content video{
    width: 100vw;
    height: 300px;
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @-moz-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @-webkit-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @-o-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @-ms-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
.SearchInput:focus-within{
    border-color: var(--primaryColor);
}

.SearchInput input::placeholder{
    color: rgb(140, 136, 136);
}

.SearchInput #search-addon,
.SearchInput input,
.SearchInput input:focus {
    height: 45px !important;
    background-color: rgba(255, 255, 255, 0.084);
    border: 1px solid white !important;
    border-left: none !important;
    transition: .2s ease-in-out;
}

 
.SearchInput #search-addon {
    border: 1px solid white !important;
    border-right: none !important;
}
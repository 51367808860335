
/* Import Poppins font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

body.dark {
  --primaryColor: #006ab1;
  --secondaryColor: #005fa9;
  --textColor: white;
  --bgColor: #16191c;
  --chatMessageUsernameColor: #f5f5f5;
  --bgOpacity: rgba(0, 0, 0, 0.2);
  --topBarBg: #1a1d21;
  --sideBarBg: #1a1d21;
  --borderColor: transparent;
  --inputPlaceHolderColor: #a7a6a8;
  --inptuBg: #16191c;
  --inputColor: #a7a6a8;
  --messageLeftBg: #1a1d21;
  --messageLeftColor: #a7a6a8;
  --messageRigthtBg: #2787F5;
  --messageRigthColor: #fff;
  
  --timestampLeftColor: #8d8a8a;
  --timestampRigthColor: #f5f5f590;
}

:root {
  --primaryColor: #006ab1;
  --secondaryColor: #e7ecf0;
  --textColor: #495057;
  --chatMessageUsernameColor: #16191c;
  --bgColor: #fff;
  --bgOpacity: rgba(255, 255, 255, 0.8);
  --topBarBg: #ffffff;
  --sideBarBg: #f6f9fb;
  --borderColor: #eeecec;
  --inptuBg: #ffffff;
  --inputColor: #333233;
  
  --messageLeftBg: #f5f6fa;


  --messageLeftColor: #404a57;
  --messageRigthtBg: #2787F5;
  --messageRigthColor: #fff;

  --timestampLeftColor: #adb5bd;
  --timestampRigthColor: #adb5bd;
}

body {
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}


 input {
  color: var(--inputColor) !important;
  background-color: var(--inptuBg) !important;
}

body:not(.dark) .ChatContent__Container {
  background-image: none !important;
}

:not(.authform) .SearchInput input:focus {
  border: none !important;
}

#search-addon {
  background-color: var(--inptuBg) !important;
}

#search-addon svg {
  color: var(--inputColor) !important;
}

:not(.authform) .ChatInput input::placeholder {
  color: var(--inputPlaceHolderColor) !important;
}

.toggle-icon {
  color: var(--primaryColor) !important;
  font-size: 1.8em;
  margin-left: 1em;
  margin-right: 1em;
  cursor: pointer;
  margin-left: 0;
  margin-right: 0;
  font-size: 1.5em;
}
/* @media (max-width: 768px) {
  .toggle-icon {
    font-size: 2em;
  }
} */
.ChatInput,
.ChatInput .ChatInput__SendButton,
.ChatInput .ChatInput__SelectFile {
 /*  background-color: var(--inptuBg) !important; */
}

.ChatInput .ChatInput__SendButton,
.ChatInput .ChatInput__SelectFile {
  border: none !important;
}

h3 {
  color: var(--textColor) !important;
}

.chat-message-username {
  color: var(--chatMessageUsernameColor) !important;
}

.ChatPage__content__topbar {
  background-color: var(--topBarBg);
  border-bottom: 1px solid var(--borderColor);
}

.ChatPage__ChatUserList {
  background-color: var(--sideBarBg);
  border-left: 1px solid var(--borderColor);
}

.RecentChatItem {
  background-color: var(--bgColor);
  border-radius: 8px;
}


.RecentChatItem {
  background-color: var(--bgColor) !important;
  border-color: var(--borderColor);
}

.RecentChatItem h5 {
  color: var(--textColor) !important;
}

body {
  padding: 0;
  margin: 0;
  background-color: var(--bgColor);
}

::selection {
  background-color: #33333350;
  color: #000;
}

:not(.authform) select {
  height: 40px !important;
  border: 1px solid var(--primaryColor) !important;
  background-color: transparent !important;
  color: black;
  font-size: 11px !important;
}

.border-primary {
  border: 1px solid var(--primaryColor) !important;
}

:not(.authform) select option {
  color: black;
  font-size: 1em !important;
}


select:focus,
input:focus,textarea:focus {
  border-width: 1px; 
  box-shadow: none !important;
  outline: none;
  border-color: none !important;
}

.small-text {
  font-size: 0.8rem;
}

.form-check-input:checked {
  background-color: var(--primaryColor);
  border-color: var(--primaryColor);
}

input:not([type="checkbox"], [type="radio"]),
input:not([type="radio"]),
button[type="submit"] {
  height: 50px;
}

select {
  color: var(--textMutedColor) !important;
  font-size: 1.1rem !important;
  font-weight: normal !important;
}

button:disabled {
  border: 1px solid #999999 !important;
  background-color: #cccccc !important;
  color: #666666 !important;
}

input[type="checkbox"] {
  height: 15px !important;
}